import LayoutAdmin from '@/layouts/LayoutAdmin'
import myTable from './table'

export default {
  data() {
    return {
      kata_kunci: null,
      pilih_cif: null,
      search: '',
      loadingTb: false,
      listCif: [],
      tbData: [],
      getData: [],
      setId: null,
      listRegister: [],
      listProject: [],

      listEvent: [],
      list_usersTypes: [],
      list_userGroup: [],
      porsi:0,
      isShow:false,
      tableDetail:[],
      setDefault:null,
      dataId:null,
      listAkun:[]

    }
  },
  created() {
    this.$emit(`update:layout`, LayoutAdmin);
  },
  mounted() {

    this.G_GetRef('register').then(data => {
      this.listRegister = data;
    })
    this.G_GetRef('akun').then(data => {
      this.listAkun = data;
    })

    // this.G_GetAny('sakti/event').then(res => {
    //   let resData = res.data.content

    //   let listData = [];
    //   let arrSub = {};

    //   resData.forEach(v => {

    //     if (v.asDefault) {
    //       this.setDefault = v.saktiEventId
    //     }
    //       arrSub = {};

    //       arrSub['text'] = v.saktiEventNm;
    //       arrSub['value'] = v.saktiEventId;

    //       listData.push(arrSub);
    //   });

    //   this.listEvent = listData;

    //   this.$refs.filter_event.setValue(this.setDefault)
      this.getTable()
      

    // })

    this.G_GetAny('porsiLoan/projek/1').then(res => {
      let resData = res.data.content

      let listData = [];
      let arrSub = {};

      resData.forEach(v => {
          arrSub = {};

          arrSub['text'] = v.projekNm;
          arrSub['value'] = v.projekId;

          listData.push(arrSub);
      });

      this.listProject = listData;

    })
   
  },
  components: {
    myTable
  },
  methods: {
    getTable() {
      this.isShow = true
      this.loadingTb = true
      // let filter = '/0'
      // if (this.$refs.filter_event.getValue()) {
      //   filter = '/'+this.$refs.filter_event.getValue()
      // }

      this.G_GetAny('register-akun').then(res => {
        console.log(res);
        this.loadingTb = false
        this.tbData = res.data.content
      })
    },
    
    edit(datas) {
     
      console.log(datas);
      this.$refs.modal.open("Edit Data Akun Register", 600)
      this.setId = datas.id
        this.$nextTick(() => {
          this.$refs.registerId.setValue(datas.register.registerId)
          this.$refs.kdakun.setValue(datas.akun.kdakun)

        });
    },
    add() {
      if (this.$refs.form) {
        this.$refs.form.reset()
        this.$refs.form.resetValidation()
      }
      this.setId = null
      this.getData = []
      this.$refs.modal.open("Tambah Data Akun Register", 600)

    },
    store() {
      let myForm = document.getElementById('formData');
      let formData = new FormData(myForm);
      formData.append('registerId',this.$refs.registerId.getValue())
      formData.append('kdakun',this.$refs.kdakun.getValue())

      
      if (this.setId) {
        formData.append('id',this.setId)
     
       
      }else{
      
      }


      if (this.$refs.form.validate()) {
          this.$refs.modal.loading()

          if (this.setId) {
            this.G_PutAny('register-akun', formData).then(res => {
              if (!res.status) {
                this.detail(this.dataId);
                this.$snotify.success('Berhasil Mengubah Data');
                this.$refs.modal.close()
              }else{
                this.$snotify.warning('Terjadi Kesalahan');
              }
            })
            .finally(() => {
              this.$refs.modal.loadingEnd()
              
            });
          }else{
            console.log('add');
            this.G_PostAny('register-akun', formData).then(res => {
              if (!res.status) {
                this.getTable();
                this.$snotify.success('Berhasil Menyimpan Data');
                this.$refs.modal.close()
              }else{
                this.$snotify.warning('Terjadi Kesalahan');
              }
            })
            .finally(() => {
              this.$refs.modal.loadingEnd()
              
            });
          }

         
      }
      else {
        this.$snotify.info('Lengkapi Data Terlebih Dahulu');
      }
    },
    detail(dataId){

      this.dataId = dataId 
      this.G_GetAny(`/register-akun/${dataId}`).then(res => {
        console.log(res);
        this.tableDetail = res.data.content

        console.log(this.tableDetail);
        this.$refs.modalDetail.open("Detail Data Akun Register", 1000)
      
      })
    },
    getRegister(id,registerId){
      this.G_GetRef('register','?projekId='+id).then(data => {
        this.listRegister = data;
      }).finally(() => {
        if(this.setId){
          this.$refs.registerId.setValue(registerId)
        }
        
      });
    },
    async hapus(id,registerId) {
      let judul = "Hapus Data"
      let subJudul = "Yakin Akan Hapus Data Ini?"

      if (await this.$refs.confirm.open(judul,subJudul) ) {
          let formData = new FormData();
          this.$refs.confirm.loading()
          this.G_DeleteAny('register-akun/'+id).then(res => {
              console.log(res);
              if (res) {
                  this.$refs.confirm.close()
                  this.G_GetAny(`/register-akun/${registerId}`).then(res => {
                    this.tableDetail = res.data.content      
                  })
                  // this.$refs.modalDetail.close()
                  this.getTable();
                  this.$snotify.success('Berhasil Hapus Data');
                  // this.$refs.notif.success(res.message,`Berhasil ${judul}`);
              }else{
                this.$snotify.warning(res.message);

                  // this.$refs.notif.warning(res.message,'Terjadi Kesalahan');
              }
          })
          .finally(() => {
              this.$refs.confirm.loadingEnd()
          }); 
      }

    },
  
  }
}